import './index.scss'
import { useState, useEffect } from 'react'
import AnimatedLetters from '../AnimatedLetters'
import Loader from 'react-loaders'
import Rehearsely from '../../assets/images/Rehearsely.png'
import TwitchDashboard from '../../assets/images/Twitch-dashboard.png'
import co2mr from '../../assets/images/co2mr.png'
import dine1 from '../../assets/images/dine1.png'
import dine2 from '../../assets/images/dine2.png'
import tft from '../../assets/images/tft-tracker.png'
import quizverse_home from '../../assets/images/quizverse_home.png'
import quizverse_create from '../../assets/images/quizverse_create.png'
import quizverse_complete from '../../assets/images/quizverse_complete.png'
import quizverse_sets from '../../assets/images/quizverse_sets.png'

const Portfolio = () => {
  const [letterClass, setLetterClass] = useState('text-animate')
  const [showModal, setShowModal] = useState({})

  const nameArray = ['M', 'Y', ' ', 'P', 'R', 'O', 'J', 'E', 'C', 'T', 'S']
  const projects = [
    {
      name: 'Machine Learning Recommendation App',
      description: `This ambitious mobile application uses Next.js, MongoDB database services, Tensorflow.js for machine learning models, and an online dietary API. \n 
      Our project, named DINE, was built for HackNYU 2023, and functions as a personlizable recipe recommendation application that emphasizes the ability for users to clearly indicate their dietary resitctions so that they can have peace of mind when they search for meals to make.Our back-end API uses Tensorflow.js for KNN classification and builds a recommendation model for each user so that their recommendations are also more tailored towards their preferences. Deployed using Vercel, and uses Chakra UI for front-end.`,
      link: 'https://dine-lilac.vercel.app/',
      images: [dine1, dine2],
    },
    {
      name: 'QuizVerse',
      description: `An easy to use spaced repetition study assistant modeled after existing related software! QuizVerse is a spaced repetition learning app that is made by students, for students. We implement a familiar flashcard learning environment, but focus on useability, simplicity, and efficiency. \n
      This project was built using the MERN stack, jwt tokens for authentication, MongoDB for database, and MUI front-end libraries for styling
      \nDevelopment followed the Scrum framework under Agile methodologies, production code has achieved 90% code coverage with Mocha and Chai unit testing.`,
      link: '',
      images: [
        quizverse_home,
        quizverse_create,
        quizverse_sets,
        quizverse_complete,
      ],
    },
    {
      name: 'Rehearsely',
      description: `Rehearsely is calendar sharing web app that allows users to merge their schedules and find the best time to meet for dance rehearsals. \n\n
      Ever been in a friend group and struggled to find a time where everyone was free? Now imagine that but with a dance team that has 10+ members!\n\n
      Rehearsely is a web application that allows users to create their calendar, and then share their calendar with others. Users can create events, send invites, receive invites, and to respond to an invitation with their schedule. Lets say your director wants to add an additional rehearsal that is outside of usual rehearsal hours, they can then create an event, select a time frame to suggest for a potential rehearsal, and then invite the company members to respond by sending their schedules. After the schedules have been received, the app will highlight overlapping free-time and the director can then use that information to make a decision based on what times will work the best for company members.\n`,
      link: '', // https://final-project-chiyanz.vercel.app
      images: [Rehearsely],
    },
    {
      name: 'TFT Tracker',
      description: `A player statistics tracking application for the game Teamfight Tactics by Riot games, utilizes registered development API key for fetching player data, allows players to tracker and analyze their in-game performance. \n 
      This project was built using TypeScript, Next.js, and uses open source DDragon in-game asset endpoints.`,
      link: '',
      images: [tft],
    },
    {
      name: 'Personalized Twitch Dashboard',
      description: `The personalized Twitch dashboard project uses Next.js, Replit database services, and gets information for user by querying the developer API that Twitch provides.\n
      Users are able to customize their own home page by providing name of streamers they wish to track, the application then makes a request to Twitch API to obtain real-time information on the stream status of the streamer being requested, such as their online status, their profile picture, etc. By doing so, Twitch users can have a simple, yet effective interface that helps them organize their list of favorite streamers and more efficiently consume entertainment by skipping them to skip accessing Twitch website for streamer data`,
      link: 'https://personalized-twitch-dashboard.chiyanz.repl.co/',
      images: [TwitchDashboard],
    },
    {
      name: 'CO2MR',
      description: `Co2mr (CO2 tomorrow) is a search engine that specializes in providing alternatives for construction materials that are more carbon-friendly. For example, if a contractor inputs "bricks" or "wood" into the search bar, they will be notified of any environmentaly friendly alternatives that have been matched with the material in our databse.\n
      The app was built for the Hackthon Superhacks 2022 and won 3rd place. It was built using Firebase backend (developed by my teammate), and React front-end (developed by me).`,
      link: '',
      images: [co2mr],
    },
  ]

  useEffect(() => {
    let timeoutId = setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 4000)
    return () => {
      clearTimeout(timeoutId)
    }
  }, [])

  const Project = ({ name, description, link, images }) => {
    const previewImages = images?.map((image, i) => {
      return (
        <img
          className="object-contain h-fit rounded-lg"
          src={image}
          alt={`project preview picture #${i}`}
        />
      )
    })

    return (
      <section id={name} className="project-element flex-col ">
        <h4>{name}</h4>
        <p>{description}</p>
        {link && (
          <a
            target="_blank"
            className="project-link"
            rel="noreferrer"
            href={link}
          >
            View Project
          </a>
        )}
        {previewImages && (
          <div className="images-container">{previewImages}</div>
        )}
      </section>
    )
  }

  const projectElements = projects.map((obj) => {
    return (
      <Project
        name={obj.name}
        description={obj.description}
        link={obj.link}
        images={obj.images}
      ></Project>
    )
  })

  const projectLinks = projects.map((obj) => {
    return (
      <a className="project-link" href={'#' + obj.name}>
        {obj.name}
      </a>
    )
  })

  console.log('Projects ', projectElements)
  console.log('given info:', projects)

  return (
    <>
      <div className="container portfolio-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={nameArray}
              idx={15}
            />
          </h1>
          <div className="project-links-container flex justify-between items-center">
            {projectLinks}
          </div>
          <div className="projects-container mb-2.5">{projectElements}</div>
        </div>
      </div>
      <Loader type="pacman" />
    </>
  )
}

export default Portfolio