import { useEffect, useState } from "react";
import Loader from "react-loaders";
import { Link } from "react-router-dom";
import AnimatedLetters from "../AnimatedLetters";
import Carousel from 'react-material-ui-carousel'
import { Paper, Button } from '@mui/material'
import './index.scss'

import dine1 from '../../assets/images/dine1.png'
import dine2 from '../../assets/images/dine2.png'
import TwitchDashboard from '../../assets/images/Twitch-dashboard.png'

const Home = () => {
  const [letterClass, setLetterClass] = useState('text-animate')
  const nameArray = ['J', 'o', 'n', 'a', 't', 'h', 'a', 'n']
  const projectImageArr = [
    <img src={dine1} alt="Dine app demo" />,
    <img src="http://random.com/two" />,
    <img src="http://random.com/three" />,
  ]

  useEffect(() => {
    let timeoutId = setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 4000)
    return () => {
      clearTimeout(timeoutId)
    }
  }, [])

  function Example(props) {
    var items = [
      {
        name: 'Random Name #1',
        description: 'Probably the most random thing you have ever seen!',
      },
      {
        name: 'Random Name #2',
        description: 'Hello World!',
      },
    ]

    return (
      <Carousel>
        {items.map((item, i) => (
          <Item key={i} item={item} />
        ))}
      </Carousel>
    )
  }

  function Item(props) {
    return (
      <Paper>
        <h2>{props.item.name}</h2>
        <p>{props.item.description}</p>

        <Button className="CheckButton">Check it out!</Button>
      </Paper>
    )
  }

  return (
    <>
      <div className="container home-page">
        <div className="text-zone">
          <h1>
            <span className={letterClass}>H</span>
            <span className={`${letterClass} _12`}>i,</span>
            <br />
            <span className={`${letterClass} _13`}>I</span>
            <span className={`${letterClass} _14`}>'m</span> &nbsp;
            <AnimatedLetters
              letterClass={letterClass}
              strArray={nameArray}
              idx={15}
            />
          </h1>
          <h2>Web Developer / Computer Science Student / Dancer</h2>
          <Link to="/contact" className="flat-button">
            CONTACT ME
          </Link>
        </div>

        {/* <div className="projects">
          <a href="/portfolio">
            <h3>My Projects</h3>
          </a>
          <div className="project">
            <a href="https://dine-lilac.vercel.app/" target="_blank">
              <h4>Dine</h4>
              <p>Machine learning personal dietary app</p>
              <div className="flex justify-around">
                <img
                  className="object-contain h-96 rounded-lg"
                  src={dine1}
                  alt={'dietary project screenshot'}
                ></img>
                <img
                  className="object-contain h-96 rounded-lg"
                  src={dine2}
                  alt={'dietary project screenshot'}
                ></img>
              </div>
            </a>
          </div>
          <div className="project">
            <a
              href="https://personalized-twitch-dashboard.chiyanz.repl.co/"
              target="_blank"
            >
              <h4>Twitch Dashboard</h4>
              <p>Personalized twitch streamer tracking app</p>
              <img
                className="object-contain h-96 rounded-lg"
                src={TwitchDashboard}
                alt={'twitch project screenshot'}
              ></img>
            </a>
          </div>
        </div> */}
      </div>
      <Loader type="pacman" />
    </>
  )
} 

export default Home 
